<template>
    <div class="m-scale">
        <div class="main-c">
            <div style="width:100%;text-align:center;">
                <div style="width:520px; margin:160px auto 40px auto;">
                    <div class="introduce">如果需要测试其他量表，请点击“直接返回”，如果不再测试，请点击“返回并退出账号”</div>
                    <div class="btns">
                        <button class="btn" @click="returnDirectly">直接返回</button>
                        <button class="btn" @click="returnAndLogout">返回并退出账号</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { logout } from "@/js/request";
export default {
    name: 'ReturnBefore',
    methods: {
        returnAndLogout(){
            logout();
            this.$cookies.remove("token");
            // location.href = "#/index";
            this.$router.push('/index');
        },
        returnDirectly(){
            // location.href = "#/index";
            this.$router.push('/index');
        },
    },
    mounted() {
        this.$root.hideRight();
    },
}
</script>

<style lang="scss">
.m-scale{
.main-c{
    padding-bottom: 20px;
    // overflow-y: scroll;
    overflow-y: auto;
    overflow-x: hidden;

    .introduce {
                    @include sys-text-hide(100);
                    @include sys-font(30px, #000);
                    line-height: 50px;
                }
    .btns {
        text-align: center;
        margin-top: 40px;
        .btn {
            @include sys-radius-btn(180px, 60px);
            margin:auto;
            margin-left: 30px;
            display: inline !important;
            font-size: 20px;
            box-sizing: border-box;
            &:first-child {
                color: rgb(255, 255, 255);
                background: rgb(253, 2, 2);
            }
        }
    }
}
}
</style>